
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  margin: 0;
}

.landing {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff5e6af;
}

.main-content {
  display: flex;
  flex: 1;
  height: calc(100vh - 10vh);
}

.sidebar {
  width: 20%;
  height: 100%;
  overflow-y: auto;
  background-color: white;
}

.landing-content-section {
  flex: 1;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar,
.landing-content-section::-webkit-scrollbar {
  width: 3px;
}

.sidebar::-webkit-scrollbar-thumb,
.landing-content-section::-webkit-scrollbar-thumb {
  background: white;
}

.sidebar::-webkit-scrollbar-thumb:hover,
.landing-content-section::-webkit-scrollbar-thumb:hover {
  background: white;
}

.hamburger {
  display: none;
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 2rem;
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .landing {
    height: 100%;
  }

  .main-content {
    height: auto;
    height: 90vh; 
  }

  .hamburger {
    display: block;
  }

  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    transform: translateX(-100%);
    height: 100vh;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .landing-content-section {
    margin-left: 0;
  }
}
