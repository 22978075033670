/* General styles */
body {
    margin: 0;
}

.table {
    width: 90%;
    margin-top: 1rem;
    background-color: transparent !important;
}

.table-responsive {
    display: flex;
    align-items: center !important;
    background-color: transparent !important;
    justify-content: center;
}

.table-body {
    border-bottom: 1px solid saddlebrown;
    border-top: 1px solid saddlebrown;
}

table,
thead,
tbody,
tr,
th,
td {
    background-color: transparent !important;
}

.cart-content {
    width: inherit;
    height: 100%;
    /* background-color: rgba(255, 192, 203, 0.627); */
    padding: 10px;
    overflow-y: scroll;
    padding-bottom: 5rem;
}

.cart-content::-webkit-scrollbar {
    width: 3px;
  }
  
  .cart-content::-webkit-scrollbar-thumb {
    background: white;
  }
  
  .cart-content::-webkit-scrollbar-thumb:hover {
    background: white;
  }

.cart-header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.cart-header h1 {
    font-family: cursive;
    font-size: 24px;
    font-weight: bolder;
}

.cart-product-container {
    width: 100%;
    height: auto;
    margin-top: 10px;
}

.container-fluid {
    margin-top: 70px;
    padding-left: 0;
    padding-right: 0;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.40rem;
}

.img-sm {
    width: 80px;
    height: 80px;
}

.itemside .info {
    padding-left: 15px;
    padding-right: 7px;
}

.table-shopping-cart .price-wrap {
    line-height: 1.2;
}

.table-shopping-cart .price {
    font-weight: bold;
    margin-right: 5px;
    display: block;
    font-size: 14px;
}

.text-muted {
    color: #969696 !important;
}

a {
    text-decoration: none !important;
}

.card {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 12px;
}

.itemside {
    display: flex;
    align-items: center;
}

.dlist-align {
    display: flex;
}

[class*="dlist-"] {
    margin-bottom: 5px;
}

.coupon {
    border-radius: 1px;
}

.price {
    font-weight: 600;
    color: brown;
}

.btn.btn-out {
    outline: 1px solid #fff;
    outline-offset: -5px;
}

.btn-main {
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
    cursor: pointer;
    color: #fff;
    width: 100%;
}

.btn-light {
    color: #ffffff;
    background-color: #F44336;
    border-color: #f8f9fa;
    font-size: 12px;
}

.btn-light:hover {
    color: #ffffff;
    background-color: #F44336;
    border-color: #F44336;
}

.btn-apply {
    font-size: 11px;
}

.aside img {
    border-radius: 5px;
}

.columns {
    color: saddlebrown;
    font-weight: bold;
    font-family: cursive;
    font-size: 14px;
}

.quantity-control {
    display: flex;
    align-items: center;
}
.dec-button{
    width: 18px;
    height: 10px;
    background-color: #dc3545;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 2px;
    color: white;
}

.remove-button{
    width: 70px;
    height: 25px !important;
    background-color: #dc3545;
    border: none;
    border-radius: 4px;
    padding-left: 3px;
    padding-right: 3px;
    color: white;
}
.quantity-control button{
    height: 20px !important;
}


.quantity-control .btn {
    padding: 5px 10px;
    margin: 0 5px;
}

.quantity-control .quantity {
    display: inline-block;
    width: 40px;
    text-align: center;
}

@media (max-width: 768px) {
    .container-fluid {
        margin-top: 20px;
    }

    .card {
        margin-bottom: 20px;
    }

    .card-body {
        padding: 1rem;
    }

    .img-sm {
        width: 200px;
        height: 200px;
    }

    .cart-header h1 {
        font-size: 20px;
    }

    .quantity-control .btn {
        padding: 3px 8px;
        margin: 0 2px;
    }

    .quantity-control .quantity {
        width: 30px;
    }

    .columns {
        font-size: 12px;
        color: saddlebrown !important;
    }

    .itemside {
        flex-direction: column;
        align-items: flex-start;
    }

    .itemside .info {
        font-size: 12px;
        padding-left: 10px;
        padding-right: 5px;
        margin-top: 5px;
    }

    .table-shopping-cart .price {
        font-size: 12px;
    }

    .table-shopping-cart .price-wrap {
        line-height: 1.1;
    }

    .btn-main {
        font-size: 13px;
        padding: 8px 15px;
    }
}

@media (max-width: 576px) {
    .cart-header h1 {
        font-size: 18px;
    }

    .quantity-control .btn {
        padding: 2px 6px;
    }

    .quantity-control .quantity {
        width: 25px;
    }

    .columns {
        font-size: 10px;
    }

    .price-wrap .price {
        font-size: 12px;
    }

    .itemside {
        flex-direction: column;
        align-items: flex-start;
    }

    .itemside .info {
        font-size: 10px;
        padding-left: 5px;
        padding-right: 3px;
        margin-top: 5px;
    }

    .btn-main {
        font-size: 12px;
        padding: 6px 10px;
    }

    .img-sm {
        width: 80px; 
        height: 80px;
    }


    .table-shopping-cart {
        font-size: 12px;
    }

    .table-shopping-cart th, .table-shopping-cart td {
        padding: 5px;
        font-size: 10px;
    }

    .table-shopping-cart .price-wrap .price {
        font-size: 12px; 
    }
}
