.about-us {
  padding: 2rem;
  color: #333333; 
  min-height: 88vh; 
  width: 100%;
  overflow-y: auto;
}

.about-us-section {
  margin-bottom: 20px;
  padding: 10px 0;
}

.about-us-header h1 {
  font-size: 2.5rem;
  color: #ed0552;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us-section h2 {
  font-size: 1.8rem;
  color: #4682B4; 
  margin-bottom: 10px; 
}

.about-us-section p {
  font-size: 1rem;
  color: #708090;
  margin-bottom: 10px; 
}

.social-media {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.social-media a {
  color: inherit;
  font-size: 2rem; 
  text-decoration: none;
}

@media (max-width: 768px) {
  .about-us{
    padding-bottom: 6rem !important;
  }
  .about-us-header h1 {
    font-size: 2rem;
    margin-bottom: 15px; 
  }

  .about-us-section h2 {
    font-size: 1.5rem;
    margin-bottom: 8px;
  }

  .about-us-section p {
    font-size: 0.9rem;
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) {
  .about-us-header h1 {
    font-size: 10vw;
    margin-bottom: 12px;
  }
  
  .about-us{
    padding-bottom: 6rem !important;
  }

  .about-us-section h2 {
    font-size: 8vw;
    margin-bottom: 6px;
  }

  .about-us-section p {
    font-size: 4vw;
    margin-bottom: 6px;
  }
}