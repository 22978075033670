.reset-password-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid saddlebrown;
    border-radius: 8px;
    /* background-color: #f9f9f9; */
  }

  .reset-password-container h2{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .field-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .field-group label {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 1rem;
  }
  
  .field-group input {
    padding: 8px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: all 0.3s;
    font-size: 1rem;
  }

  .field-group input:focus{
    /* border: none; */
    outline: none;
  }
  
  .field-group button {
    padding: 8px;
    background-color: saddlebrown;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 14px;
  }
  
  .field-group button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .status {
    margin-top: 5px;
    color: green;
    font-size: 14px;
  }
  
  .status.verified {
    font-weight: bold;
  }
  
  .blurred {
    opacity: 0.5;
    pointer-events: none;
  }
  
  @media (max-width: 768px) {
    .reset-password-container {
      padding: 15px;
    }
  
    .field-group {
      margin-bottom: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .reset-password-container {
      padding: 10px;
    }
  
    .field-group {
      margin-bottom: 8px;
    }
  }
  

  .reset-password-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .reset-password-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .reset-password-header h1 {
    font-size: 2rem;
    color: saddlebrown;
    margin: 0;
  }
  
  .reset-password-header p {
    font-size: 0.8rem;
    color: #555;
  }
  
  .reset-password-footer {
    text-align: center; 
    padding: 0;
    width: 100%;
    color:white;
  }
  
  .reset-password-footer p {
    font-size: 1rem;
    color: #555;
  }
  
  .reset-password-footer a {
    color: saddlebrown;
    text-decoration: none;
    font-weight: bold;
  }
  
  .reset-password-footer a:hover {
    text-decoration: underline;
  }



  .reset-form-section{
    /* background-color: red; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }
  
  /* Include the existing reset-password-container, field-group, and other styles here */
  