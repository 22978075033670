.home {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.upper-layer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.info-container {
  width: 92%;
  height: auto;
  border-radius: 24px;
  padding: 2rem;
  margin: 2rem 0;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
}

.info-container::-webkit-scrollbar {
  width: 3px;
}

.info-container::-webkit-scrollbar-thumb {
  background: white;
}

.info-container::-webkit-scrollbar-thumb:hover {
  background: white;
}

.header h1 {
  color: orchid; 
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); 
  display: flex;
  justify-content: center;
}

.home_content p {
  color: #2F4F4F;
  font-size: 1.1rem; 
  line-height: 1.6;
}

.details_order p {
  color: orange; 
}

.home_content a {
  color: #FF4500;
  text-decoration: none;
  position: relative;
}

.home_content a::after {
  content: '';
  width: 0;
  height: 2px;
  background: #FF4500;
  position: absolute;
  left: 0;
  bottom: -2px;
  transition: width 0.3s ease-in-out;
}

.home_content a:hover::after {
  width: 100%;
}

@media only screen and (max-width: 1245px) {
  .home {
    width: 100vw;
    height: calc(100vh - 12vh);
    display: flex;
    flex-direction: column;

  }

  .header h1{
    font-size: 1.5rem;
    text-align: center;
  }
  .info-container {
    margin-top: 0 ;
  }

  .upper-layer {
    padding-top: 0.8rem;
    background-color: rgba(255, 255, 255, 0.2);
    padding-bottom: 1rem !important;
  }

  .home_content h1 {
    font-size: 1.5rem;
  }

  .home_content p,
  .details_order p {
    font-size: 0.9rem;
  }
}
