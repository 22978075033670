  .pair-quantity-input-group{
    width: inherit;
    height: 100%;
    margin-bottom: 0.2rem;

  }

  .quanityt-input-group{
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .single-quanityt-input-group{
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    display: flex;
  }


  .name-form-group{
    width: 100%;
    height: 100%;
  }

  .name-input-feild{
    width: 100%;
    padding: 0.4rem;
    border-radius: 2px;
    border: 1px solid saddlebrown
  }
.name-input-feild:focus{
    outline: none;
}

.incre-decre-btn{
    width: 10%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    color: black;
    font-weight: bold;
    cursor: pointer;
}
.incre-decre-btn div {
  cursor: pointer;
}
