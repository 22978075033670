.login-form {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .inner-section { 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #f3e5f5;
    overflow-y: auto;
  }
  .form-group input:focus {
    outline: none !important;
    box-shadow: none !important;
}

  .form-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: auto;
  }
  
  .login-form-inner {
    width: 40%;
    background-color: #fff5e6af;
    padding: 1.5rem;
    border-radius: 25px;
  }
  
  .form-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .form-title {
    font-size: 1.5rem;
    color: saddlebrown;
    margin-right: 0.5rem;
  }
  
  .form-icon {
    color: saddlebrown;
    font-size: 2rem;
  }
  
  .form-label {
    font-weight: bold;
    color: saddlebrown;
  }
  
  .form-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .oauth-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    color: saddlebrown;
  }
  
  .oauth-text {
    margin-right: 1rem;
  }
  
  .oAuth-login {
    color: saddlebrown;
    font-size: 2rem;
  }
  
  .oAuth-login:hover {
    cursor: pointer;
  }
  
  .signup-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    color: saddlebrown;
  }
  
  .signup-text {
    color: saddlebrown;
  }
  
  .signup-link {
    margin-left: 0.5rem;
    cursor: pointer;
    text-decoration: underline;
  }

  .submit-btn{
    background-color: saddlebrown;
    border: none;
    color: white;
  }
  .submit-btn:hover{ 
    background-color: saddlebrown  !important;
    border: none;
    color:white;
  }
  
.btn-fixed {
  width: 120px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: white;
  background-color: saddlebrown  !important;
}

.btn-fixed:focus {
  outline: none; 
  box-shadow: none;
}

.send-otp{
  border: none;
  /* color: cornflowerblue; */
}
.forgot-password:hover{
  cursor: pointer;
}
  /* Responsive Design */
  @media (max-width: 768px) {
    .form-section {
      width: 80%;
    }
  
    .login-form-inner {
      width: 80%;
    }
  }
  
  @media (max-width: 480px) {
    .form-section {
      width: 90%;
    }
  
    .login-form-inner {
      width: 100%;
      padding: 1rem;
    }
  
    .form-title {
      font-size: 1.2rem;
    }
  
    .form-icon {
      font-size: 1.5rem;
    }
  
    .oAuth-login {
      font-size: 1.5rem;
    }
  }
  