.pencil-home {
    position: relative;
  }
  
  .spinner {
    position: absolute;
    left: 40%;
    top: 40%;
  }
  
  .pencil-art-header{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: cursive;
  }
  .product {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color:'red' !important;
    padding: 1rem 1rem 1rem 1rem;
  }
  
  .product-container {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    border-radius: 12px;
    width: 307px;
    height: 330px;
    background-color: rgba(255, 192, 203, 0.498);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .image {
    width: 100%;
    height: 80%; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
  
  .image-container .price {
    position: absolute;
    top: 10px; 
    right: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-family: cursive;
  }
  
  .button {
    width: 100%;
    height: 20%; 
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0;
  }
  
  .button button {
    border-radius: 10px;
    font-size: 1rem;
    font-weight: bold;
    font-family: cursive;
  }
  
  @media only screen and (max-width: 1000px) {
    .pencil-art-header{
      display: block;
      font-weight: bolder;
      display: flex;
      justify-content: center;
      font-family: cursive;
    }
    .product {
      display: flex;
      align-items: center;
      height: fit-content;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
    .product-container {
      margin-top: 1rem;
      margin-left: 0.5rem;
      width: 45vw;
      height: 35vh;
      background-color: rgba(255, 192, 203, 0.395) !important;

    }
    .button button {
      font-size: 2.5vw;
      border-radius: 6px !important;
    }
  }
  