.order-history {
  width: 100%;
  height: 100%;
  overflow-y: scroll !important;
  padding-bottom: 5rem;

}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  position: relative;
  z-index: 1;
}

.card-footer {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

.position-relative {
  position: relative;
}

.order-tracker {
  width: 95%;
}

.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.orderhistory-header{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
}

.card {
  background-color: lavender !important;
  color: saddlebrown !important;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.progress-line {
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  left: 9%;
  width: 80%;
  height: 2px;
  background: linear-gradient(to right, green 50%, var(--bs-secondary) 50%);
  z-index: 0;
}

.cancell-order:hover{
  cursor: pointer;
}

.card-body h5 {
  font-size: 0.8rem;
}

.order-history::-webkit-scrollbar {
  width: 3px;
}

.order-history::-webkit-scrollbar-thumb {
  background: white;
}

.order-history::-webkit-scrollbar-thumb:hover {
  background: white;
}

.card-img-top {
  width: 120px;
  height: 120px;
}

@media (max-width: 768px) {
  .order-history {
    width: 100%;
    height: auto;
    max-height: calc(100vh - 10vh);
  }
  .orderhistory-header{
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 13px;
  }
}
